const routes = [
    {
        path: '/grupos_tributacoes/:id?',
        component: () => import('@/shared/components/layout/Interno'),
        children: [
            {
                name: 'listarTributacoes',
                path: '/grupos_tributacoes',
                alias: '/grupos_tributacoes/index',
                component: () => import('../views/Listar')
            },
            {
                name: 'adicionarTributacao',
                path: '/grupos_tributacoes/adicionar',
                component: () => import('../views/Adicionar')
            },
            {
                name: 'editarTributacao',
                path: '/grupos_tributacoes/editar/:id',
                component: () => import('../views/Editar')
            },
            {
                name: 'visualizarTributacao',
                path: '/grupos_tributacoes/visualizar/:id',
                component: () => import('../views/Visualizar')
            }
        ]
    }
]

export default routes
